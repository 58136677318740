import React, { useState, type MouseEvent } from 'react';
import { type BarDatum, type BarCustomLayerProps } from '@nivo/bar/dist/types/types';
import { useTooltip } from '@nivo/tooltip';

export const FocusableBars = <T extends BarDatum>(props: BarCustomLayerProps<T>) => {
  const [focusedBarIndex, setFocusedBar] = useState(-1);

  const {
    bars,
    innerHeight,
    height,
    margin: { bottom },
  } = props;
  const tip = useTooltip();

  const singleSidePadding = bars.length > 0 ? (bars[1].x - bars[0].x - bars[0].width) / 2 : 0;

  const renderTip = (_: MouseEvent, i: number) => {
    const { absX, width: barWidth } = bars[i];
    const tooltipWidthOffset = 306 / 2; // 240px width centered
    const tooltipPadding = 20; // 20px padding
    const tooltipOffsetFromBar = 10; // 5px offset from bar

    const tooltipPosition = window.innerHeight / 2 - absX < 0 ? 'left' : 'right'; // Tooltip position based on bar position
    const tooltipTotalOffset =
      tooltipWidthOffset +
      tooltipPadding +
      tooltipOffsetFromBar +
      (tooltipPosition === 'right' ? barWidth : 0);

    tip.showTooltipAt(
      React.createElement(props.tooltip, {
        ...bars[i],
        ...bars[i].data,
        value: bars[i].data.value || 0,
      }),
      [
        tooltipPosition === 'left' ? absX - tooltipTotalOffset : absX + tooltipTotalOffset,
        height - bottom,
      ],
    );
  };

  return (
    <>
      <g>
        {bars.map((bar, i) => (
          <rect
            key={bar.key}
            x={bar.x - singleSidePadding}
            y={0}
            height={innerHeight}
            width={bar.width + singleSidePadding * 2}
            fill="transparent"
            onMouseEnter={(e) => {
              renderTip(e, i);
              setFocusedBar(bar.data.index);
            }}
            onMouseMove={(e) => renderTip(e, i)}
            onMouseLeave={() => {
              tip.hideTooltip();
              setFocusedBar(-1);
            }}
          />
        ))}
      </g>
      <g>
        {bars.map((bar) => (
          <rect
            className="pointer-events-none"
            key={bar.key}
            x={bar.x}
            y={bar.y}
            height={bar.height}
            width={bar.width}
            fill={
              focusedBarIndex === -1 || focusedBarIndex === bar.data.index
                ? 'transparent'
                : 'rgba(0,0,0, 0.6)'
            }
          />
        ))}
      </g>
    </>
  );
};
