import ShadowDom from '../components/ShadowDom';

export const ChainflipLogo = (props: Record<string, string>) => (
  // prevent id collisions
  <ShadowDom className="flex h-[22px] items-center">
    <svg
      width="124"
      height="22"
      viewBox="0 0 124 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.3761 16.7386C37.3574 16.7386 39.7462 14.8453 39.7462 11.6779V11.3771H36.0303V11.6779C36.0303 13.0227 35.3933 13.5535 33.4292 13.5535C31.0227 13.5535 30.368 12.9873 30.368 10.6339C30.368 8.28055 31.0227 7.71433 33.4292 7.71433C35.3933 7.71433 36.0303 8.24516 36.0303 9.58995V9.89076H39.7462V9.58995C39.7462 6.42262 37.3574 4.5293 33.3761 4.5293C29.2533 4.5293 26.8291 6.93576 26.8291 10.6339C26.8291 14.3321 29.2533 16.7386 33.3761 16.7386Z"
        fill="white"
      />
      <path
        d="M43.9492 16.5616V12.1026H49.4699V16.5616H52.7965V4.70624H49.4699V9.1122H43.9492V4.70624H40.6226V16.5616H43.9492Z"
        fill="white"
      />
      <path
        d="M56.8848 16.5616L57.8049 14.3675H63.4318L64.3342 16.5616H68.1562L63.0248 4.70624H58.2649L53.1512 16.5616H56.8848ZM59.7513 9.71382L60.5122 7.71433H60.7422L61.5208 9.71382L62.2108 11.3771H59.0612L59.7513 9.71382Z"
        fill="white"
      />
      <path d="M71.8389 16.5616V4.70624H68.5123V16.5616H71.8389Z" fill="white" />
      <path
        d="M76.228 16.5616V11.9256L76.0865 9.66073H76.3165L77.8205 11.9964L81.3241 16.5616H84.863V4.70624H81.5364V9.28915L81.6956 11.8726H81.4656L79.7315 9.14759L76.4403 4.70624H72.9014V16.5616H76.228Z"
        fill="white"
      />
      <path
        d="M89.257 16.5616V12.4388H96.2641V9.46609H89.257V7.69663H96.5295V4.70624H85.9305V16.5616H89.257Z"
        fill="white"
      />
      <path d="M107.579 16.5616V13.5712H100.731V4.70624H97.4043V16.5616H107.579Z" fill="white" />
      <path d="M111.79 16.5616V4.70624H108.463V16.5616H111.79Z" fill="white" />
      <path
        d="M116.179 16.5616V13.5712H119.417C122.231 13.5712 124 11.9433 124 9.12989C124 6.31645 122.231 4.70624 119.417 4.70624H112.853V16.5616H116.179ZM119.046 7.69663C120.213 7.69663 120.691 7.92666 120.691 9.12989C120.691 10.3508 120.213 10.5809 119.046 10.5809H116.179V7.69663H119.046Z"
        fill="white"
      />
      <g clipPath="url(#clip0_3568_50677)">
        <path
          d="M21.0972 0.501953V9.484C21.0972 11.0808 20.592 12.6277 19.6801 13.9002C19.3966 14.2994 19.0762 14.6611 18.7189 14.998L18.6942 15.0104H14.4797V6.68958H10.5732H6.62981H6.28476H2.39062L2.43992 6.63968L5.74254 3.53339L6.96253 2.39816C8.34273 1.10076 10.0433 0.501953 11.9165 0.501953H21.0972Z"
          fill="url(#paint0_linear_3568_50677)"
        />
        <path
          d="M18.6943 15.0228L18.682 15.0353L18.6697 15.0478L18.4478 15.2598L17.6099 16.0582L14.566 18.915L14.2703 19.1895C12.8901 20.4744 11.0786 21.173 9.21776 21.173H0V17.1561L0.0123232 15.0103L0.0369696 12.1536C0.0492928 10.0702 0.899594 8.09916 2.40302 6.677H6.29716H6.30948V15.0103H18.6943V15.0228Z"
          fill="url(#paint1_linear_3568_50677)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3568_50677"
          x1="12.1865"
          y1="-0.384138"
          x2="10.977"
          y2="22.4123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48EF8A" />
          <stop offset="0.5458" stopColor="#43B4A6" />
          <stop offset="0.6918" stopColor="#2D796F" />
          <stop offset="0.9662" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3568_50677"
          x1="9.90364"
          y1="22.3039"
          x2="6.72857"
          y2="-1.05551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F96" />
          <stop offset="0.6103" stopColor="#FF33AF" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_3568_50677">
          <rect width="21.0973" height="20.6837" fill="white" transform="translate(0 0.501953)" />
        </clipPath>
      </defs>
    </svg>
  </ShadowDom>
);
