const Vault = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      d="M3.16667 4.51064C3.16667 3.77426 3.76363 3.17731 4.50001 3.17731H11.5C12.2364 3.17731 12.8333 3.77426 12.8333 4.51064V10.1773C12.8333 10.9137 12.2364 11.5106 11.5 11.5106H4.50001C3.76363 11.5106 3.16667 10.9137 3.16667 10.1773V4.51064Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33333 11.6773V12.844"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 11.6773V12.844"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1667 7.34397C10.1667 8.54057 9.19659 9.51064 7.99999 9.51064C6.80339 9.51064 5.83333 8.54057 5.83333 7.34397C5.83333 6.14735 6.80339 5.17731 7.99999 5.17731C9.19659 5.17731 10.1667 6.14735 10.1667 7.34397Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Vault;
