import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { type PageInfo, type Maybe, type Scalars } from '@/shared/graphql/generated/graphql';
import { type HookOptions, useGqlQuery } from '@/shared/hooks/useGqlQuery';
import useClampedValue from './useClampedValue';
import useCounter, { type Counter } from './useCounter';
import useCursorPagination, { type Cursor } from './useCursorPagination';
import { clamp, safeParseInt } from '../utils';

export interface PaginationControls {
  pageCounter: Counter;
  limit: number;
  setLimit: (limit: number) => void;
  useCursor: boolean;
  paginationLimits: number[];
  paginationCursor: Cursor;
}

export interface BasePaginationData {
  paginationControls: PaginationControls;
}

interface LoadingPaginationData extends BasePaginationData {
  isLoading: true;
  data: null;
  error: null;
}

interface LoadedPaginationData<T> extends BasePaginationData {
  isLoading: false;
  data: T | null;
  error: null;
}

interface ErrorPaginationData extends BasePaginationData {
  isLoading: false;
  data: null;
  error: Error;
}

type PaginationData<T> = LoadedPaginationData<T> | LoadingPaginationData | ErrorPaginationData;

export default function useRemotePagination<
  Data,
  Variables extends {
    first?: Maybe<Scalars['Int']['input']>;
    last?: Maybe<Scalars['Int']['input']>;
    offset?: Maybe<Scalars['Int']['input']>;
    before?: Maybe<Scalars['String']['input']>;
    after?: Maybe<Scalars['String']['input']>;
  },
>(
  query: TypedDocumentNode<Data, Variables>,
  {
    useQueryParam = false,
    totalCount = undefined,
    initialLimit = 20,
    paginationLimits = [20, 40, 60, 80, 100],
    useCursor = false,
    pageInfo,
    ...options
  }: HookOptions<Variables> & {
    enabled?: boolean;
    refetchInterval?: number | false;
    useQueryParam?: boolean;
    totalCount?: number | ((data: Data) => number);
    initialLimit?: number;
    paginationLimits?: number[];
    useCursor?: boolean;
    pageInfo?: (data: Data) => PageInfo | undefined;
  } = {},
): PaginationData<Data> {
  const router = useRouter();

  let initialPage = 1;
  const params =
    typeof window !== 'undefined' && useQueryParam && new URLSearchParams(window.location.search);
  if (params) {
    const page = safeParseInt(params.get('page'));
    if (page !== null) initialPage = clamp(page, 1, Infinity);
  }

  const [limit, setLimit] = useState(() => {
    if (params) {
      const queryLimit = safeParseInt(params.get('limit'));
      if (queryLimit !== null && paginationLimits.includes(queryLimit)) {
        return queryLimit;
      }
    }

    return initialLimit;
  });
  const [totalPages, setTotalPages] = useClampedValue({ start: initialPage });
  const pageCounter = useCounter({
    max: totalPages,
    start: initialPage,
  });
  const skipRecords = useMemo(
    () => (pageCounter.current - 1) * limit,
    [pageCounter.current, limit],
  );

  const paginationCursor = useCursorPagination();

  // push to browser history when the user changes the pagination parameters
  useEffect(() => {
    if (useQueryParam) {
      const { page: oldPage, limit: oldLimit, ...q } = router.query;

      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...q,
            ...(pageCounter.current === 1 ? null : { page: pageCounter.current }),
            ...(limit === initialLimit ? null : { limit }),
          },
        },
        undefined,
        { scroll: false },
      );
    }
  }, [pageCounter.current, limit, useQueryParam]);

  // change the page/limit when the user goes back or forward in the browser history
  useEffect(() => {
    if (useQueryParam && typeof router.query.page === 'string') {
      const queryPageNumber = Number.parseInt(router.query.page, 10);
      if (!Number.isNaN(queryPageNumber) && queryPageNumber !== pageCounter.current) {
        pageCounter.set(queryPageNumber);
      }
    }
  }, [router.query.page, useQueryParam]);
  useEffect(() => {
    if (useQueryParam && typeof router.query.limit === 'string') {
      const queryLimitNumber = Number.parseInt(router.query.limit, 10);
      if (!Number.isNaN(queryLimitNumber) && queryLimitNumber !== limit) {
        setLimit(queryLimitNumber);
      }
    }
  }, [router.query.limit, useQueryParam]);

  const result = useGqlQuery(query, {
    context: { clientName: 'processor' },
    ...options,
    variables: {
      ...options?.variables,
      ...(useCursor && {
        before: paginationCursor.before,
        after: paginationCursor.after,
        first: paginationCursor.before == null ? limit : undefined,
        last: paginationCursor.before ? limit : undefined,
      }),
      ...(!useCursor && {
        first: limit,
      }),
      ...(skipRecords !== 0 && { offset: skipRecords }),
    } as Variables,
  });

  useEffect(() => {
    if (result.isLoading || !result?.data) return;

    // try to read the total count from the first key of the query result by default
    const key = Object.keys(result.data)[0] as keyof typeof result.data;
    const typedData = result.data[key] as { totalCount?: number } | undefined;

    if (totalCount) {
      if (typeof totalCount === 'number') {
        setTotalPages(Math.ceil(totalCount / limit));
      } else {
        setTotalPages(Math.ceil(totalCount(result.data) / limit));
      }
    } else if (typedData?.totalCount) {
      setTotalPages(Math.ceil(typedData.totalCount / limit));
    }

    if (pageInfo) {
      const pageInfoProp = pageInfo(result.data);
      if (pageInfoProp) paginationCursor.setCursorPageInfo(pageInfoProp);
    } else {
      const pageInfoProp = result.data[key] as
        | {
            pageInfo: PageInfo;
          }
        | undefined;
      if (pageInfoProp?.pageInfo) {
        paginationCursor.setCursorPageInfo(pageInfoProp.pageInfo);
      }
    }
  }, [result?.data, totalCount, result.isLoading]);

  const paginate = useMemo(
    () => ({
      data: result.data ?? null,
      error: result.error ?? null,
      isLoading: result.isLoading,
      paginationControls: {
        pageCounter,
        limit,
        setLimit,
        paginationLimits,
        useCursor,
        paginationCursor,
      },
    }),
    [pageCounter, limit, setLimit, result.data, result.isLoading, result.error, paginationCursor],
  );

  return paginate as PaginationData<Data>;
}
