import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  type DefaultError,
  type QueryKey,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type RequestOptions } from 'graphql-request';
import {
  cacheClient,
  lpProcessorClient,
  processorClient,
  reportingClient,
} from '@/shared/graphql/client';

const clients = {
  processor: processorClient,
  statechainCache: cacheClient,
  lpProcessor: lpProcessorClient,
  reportingService: reportingClient,
} as const;
type Client = keyof typeof clients;

export type HookOptions<TVariables> = {
  variables?: TVariables;
  context?: { clientName: Client };
};

type QueryOptions<TVariables, TError, TData, TQueryKey extends QueryKey, TResult = TData> = Omit<
  UseQueryOptions<TData, TError, TResult, TQueryKey> & HookOptions<TVariables>,
  'queryKey' | 'queryFn'
>;

export function useGqlQuery<
  TData = unknown,
  TResult = TData,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
  TError = DefaultError,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryOptions<TVariables, TError, TData, QueryKey, TResult>,
) {
  const { variables, context, ...rest } = options ?? {};
  const client = clients[context?.clientName ?? 'processor'];

  return useQuery({
    ...rest,
    queryKey: [query, variables],
    queryFn: () =>
      client.request({
        document: query,
        variables,
      } as unknown as RequestOptions<TVariables, TData>),
  });
}
