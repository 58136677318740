import { useEffect } from 'react';
import classNames from 'classnames';
import useQueryParam from '@/shared/hooks/useQueryParam';

export const TabPanel = ({
  nodes,
  contentRight,
  shouldOverflow = true,
  queryParamName,
  onSetActive,
}: {
  nodes: {
    name: string;
    content: JSX.Element;
    count?: number | string;
    contentRight?: JSX.Element;
  }[];
  contentRight?: React.ReactNode;
  shouldOverflow?: boolean;
  queryParamName?: string;
  onSetActive?: (nodeName: string) => void;
}) => {
  const [active, setActive] = useQueryParam({
    defaultValue: nodes[0].name,
    name: queryParamName,
    parse: (value) => nodes.find((node) => node.name === value)?.name ?? nodes[0].name,
    scroll: false,
  });
  const activeNode = nodes.find((node) => node.name === active);

  useEffect(() => {
    if (!activeNode) {
      setActive(nodes[0].name);
    }
  }, [nodes.length]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between overflow-x-auto rounded-t-md border border-b-0 border-cf-gray-3-5 bg-cf-gray-2">
        <div className="flex overflow-x-auto">
          {nodes.map((node) => (
            <button
              type="button"
              key={node.name}
              className={classNames(
                `flex items-center gap-x-2 whitespace-nowrap border-r border-cf-gray-3-5 px-[20px] py-[12px] font-aeonikMedium text-14 text-cf-light-2 transition duration-100 ease-in first-of-type:rounded-tl-md hover:bg-cf-gray-3 hover:text-cf-light-3`,
                active === node.name
                  ? 'bg-cf-gray-4 text-cf-light-3 hover:bg-cf-gray-4'
                  : 'bg-cf-gray-2',
              )}
              onClick={() => {
                if (active !== node.name) {
                  setActive(node.name);
                  onSetActive?.(node.name);
                }
              }}
            >
              {node.name}
              {node.count !== undefined && (
                <span className="flex h-[18px] items-center rounded-[3px] border border-cf-green-1/20 bg-cf-green-4 px-[5px] text-cf-green-1">
                  {node.count}
                </span>
              )}
            </button>
          ))}
        </div>
        {activeNode?.contentRight ?? contentRight}
      </div>
      <div
        className={classNames(
          'cf-is-panel group flex w-full flex-col',
          shouldOverflow && 'overflow-x-auto',
        )}
      >
        {activeNode?.content}
      </div>
    </div>
  );
};
