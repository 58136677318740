import React, { useEffect } from 'react';
import { flip, offset, shift, useFloating } from '@floating-ui/react-dom';

export const ChartTooltipV2 = ({
  children,
  activeXAxisItem,
}: {
  children: React.ReactNode;
  activeXAxisItem?: number;
}) => {
  // use floating-ui to prevent to tooltip from overflowing the viewport when its rendered near the edges of the window
  const { refs, floatingStyles, update } = useFloating({
    placement: 'top',
    middleware: [offset(8), flip(), shift({ padding: 8 })],
  });
  useEffect(update, [activeXAxisItem]); // update floating-ui calculations when active x-axis value changes

  return (
    <>
      <div ref={refs.setReference} />
      <div
        className="rounded-md border p-4 shadow-[0_4px_30px_0_rgba(0,0,0,0.5)]"
        ref={refs.setFloating}
        style={{
          ...floatingStyles,
          backdropFilter: 'blur(28px)',
          borderColor: 'rgba(255, 255, 255, 0.10)',
          backgroundColor: 'rgba(37, 37, 37, 0.60)',
        }}
      >
        {children}
      </div>
    </>
  );
};
