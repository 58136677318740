const Vault = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M4.74835 6.75082C4.74835 5.64625 5.64378 4.75082 6.74835 4.75082H17.2484C18.353 4.75082 19.2484 5.64625 19.2484 6.75082V15.2508C19.2484 16.3554 18.353 17.2508 17.2484 17.2508H6.74835C5.64378 17.2508 4.74835 16.3554 4.74835 15.2508V6.75082Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99835 17.5008V19.2508"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9984 17.5008V19.2508"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2484 11.0008C15.2484 12.7957 13.7933 14.2508 11.9984 14.2508C10.2035 14.2508 8.74835 12.7957 8.74835 11.0008C8.74835 9.20589 10.2035 7.75082 11.9984 7.75082C13.7933 7.75082 15.2484 9.20589 15.2484 11.0008Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Vault;
