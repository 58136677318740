const Filter = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.16666 4.67731C2.89052 4.67731 2.66666 4.90117 2.66666 5.17731C2.66666 5.45345 2.89052 5.67731 3.16666 5.67731V4.67731ZM12.8333 5.67731C13.1095 5.67731 13.3333 5.45345 13.3333 5.17731C13.3333 4.90117 13.1095 4.67731 12.8333 4.67731V5.67731ZM3.16666 5.67731H12.8333V4.67731H3.16666V5.67731Z"
      fill="white"
    />
    <path
      d="M4.5 7.34393C4.22386 7.34393 4 7.5678 4 7.84393C4 8.12007 4.22386 8.34393 4.5 8.34393V7.34393ZM11.5 8.34393C11.7761 8.34393 12 8.12007 12 7.84393C12 7.5678 11.7761 7.34393 11.5 7.34393V8.34393ZM4.5 8.34393H11.5V7.34393H4.5V8.34393Z"
      fill="white"
    />
    <path
      d="M5.83334 10.0106C5.5572 10.0106 5.33334 10.2345 5.33334 10.5106C5.33334 10.7868 5.5572 11.0106 5.83334 11.0106V10.0106ZM10.1667 11.0106C10.4428 11.0106 10.6667 10.7868 10.6667 10.5106C10.6667 10.2345 10.4428 10.0106 10.1667 10.0106V11.0106ZM5.83334 11.0106H10.1667V10.0106H5.83334V11.0106Z"
      fill="white"
    />
  </svg>
);

export default Filter;
