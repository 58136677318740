const Filter = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.74835 7.00082C4.33414 7.00082 3.99835 7.33661 3.99835 7.75082C3.99835 8.16503 4.33414 8.50082 4.74835 8.50082V7.00082ZM19.2484 8.50082C19.6626 8.50082 19.9984 8.16503 19.9984 7.75082C19.9984 7.33661 19.6626 7.00082 19.2484 7.00082V8.50082ZM4.74835 8.50082H19.2484V7.00082H4.74835V8.50082Z"
      fill="white"
    />
    <path
      d="M6.74835 11.0008C6.33414 11.0008 5.99835 11.3366 5.99835 11.7508C5.99835 12.165 6.33414 12.5008 6.74835 12.5008V11.0008ZM17.2484 12.5008C17.6626 12.5008 17.9984 12.165 17.9984 11.7508C17.9984 11.3366 17.6626 11.0008 17.2484 11.0008V12.5008ZM6.74835 12.5008H17.2484V11.0008H6.74835V12.5008Z"
      fill="white"
    />
    <path
      d="M8.74835 15.0008C8.33414 15.0008 7.99835 15.3366 7.99835 15.7508C7.99835 16.165 8.33414 16.5008 8.74835 16.5008V15.0008ZM15.2484 16.5008C15.6626 16.5008 15.9984 16.165 15.9984 15.7508C15.9984 15.3366 15.6626 15.0008 15.2484 15.0008V16.5008ZM8.74835 16.5008H15.2484V15.0008H8.74835V16.5008Z"
      fill="white"
    />
  </svg>
);

export default Filter;
