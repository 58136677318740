import React from 'react';
import { BigNumber } from 'bignumber.js';
import classNames from 'classnames';
import {
  TokenAmountWithChainByAsset,
  TokenWithChainByAsset,
} from '@/shared/components/TokenWithChain';
import { type SwapRequestFragment, type ChainflipAsset } from '@/shared/graphql/generated/graphql';
import RightDouble from '@/shared/icons/flip-ui-kit/small/RIghtDouble';
import { ArrowIcon } from '@/shared/icons/large';
import { chainflipAssetMap, TokenAmount } from '@/shared/utils';
import { Tooltip } from './molecules/Tooltip';
import RouteTooltip, { type RouteTooltipField } from './RouteTooltip';
import { SwapSteps } from './SwapSteps';

export const SwapRouteArrow = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'relative m-auto h-[24px] w-[24px] rounded-full border-[0.75px] border-cf-gray-3-5 p-1 shadow-[0px_0px_0px_0.75px_#191919] [background:linear-gradient(180deg,rgba(255,255,255,0.06)_-19.26%,rgba(255,255,255,0.00)_60.89%),#252525]',
      className,
    )}
  >
    <RightDouble className="absolute bottom-[2.3px] right-[3px] text-cf-light-2" />
  </div>
);

type SwapStep = { asset: ChainflipAsset; amount?: string; amountUsd?: string };

const getSwapSteps = (
  swapRequests: Pick<SwapRequestFragment, 'executedSwaps'>[],
  sourceAsset: ChainflipAsset,
  destinationAsset: ChainflipAsset,
): [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep] => {
  const totals = swapRequests.reduce(
    (acc, swapRequest) => {
      const sums = swapRequest.executedSwaps.aggregates?.sum;
      acc.inputAmount = acc.inputAmount.plus(sums?.swapInputAmount ?? 0);
      acc.inputAmountUSD = acc.inputAmountUSD.plus(sums?.swapInputValueUsd ?? 0);
      acc.intermediateAmount = acc.intermediateAmount.plus(sums?.intermediateAmount ?? 0);
      acc.intermediateAmountUSD = acc.intermediateAmountUSD.plus(sums?.intermediateValueUsd ?? 0);
      acc.outputAmount = acc.outputAmount.plus(sums?.swapOutputAmount ?? 0);
      acc.outputAmountUSD = acc.outputAmountUSD.plus(sums?.swapOutputValueUsd ?? 0);
      return acc;
    },
    {
      inputAmount: new BigNumber(0),
      inputAmountUSD: new BigNumber(0),
      outputAmount: new BigNumber(0),
      outputAmountUSD: new BigNumber(0),
      intermediateAmount: new BigNumber(0),
      intermediateAmountUSD: new BigNumber(0),
    },
  );

  const sourceAmount = TokenAmount.fromAsset(totals.inputAmount, sourceAsset);
  const hasSourceAmount = (sourceAmount && !sourceAmount.eq(0)) || undefined;
  const sourceAmounts = {
    asset: sourceAsset,
    amount: hasSourceAmount && sourceAmount?.toFormatted(),
    amountUsd: hasSourceAmount && totals.inputAmountUSD.toFixed(),
  };

  const destinationAmount = TokenAmount.fromAsset(totals.outputAmount, destinationAsset);
  const hasDestinationAmount = (destinationAmount && !destinationAmount.eq(0)) || undefined;
  const destinationAmounts = {
    asset: destinationAsset,
    amount: hasDestinationAmount && destinationAmount?.toFormatted(),
    amountUsd: hasDestinationAmount && totals.outputAmountUSD.toFixed(),
  };

  const intermediateAmount = TokenAmount.fromAsset(totals.intermediateAmount, 'Usdc');
  const hasIntermediateAmount = (intermediateAmount && !intermediateAmount.eq(0)) || undefined;
  const intermediateAmounts = {
    asset: 'Usdc' as ChainflipAsset,
    amount: hasIntermediateAmount && intermediateAmount?.toFormatted(),
    amountUsd: hasIntermediateAmount && totals.intermediateAmountUSD.toFixed(),
  };

  if (sourceAsset === 'Usdc' || destinationAsset === 'Usdc') {
    return [sourceAmounts, destinationAmounts];
  }

  return [sourceAmounts, intermediateAmounts, destinationAmounts];
};

export const SwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swapRequests,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swapRequests?: Pick<SwapRequestFragment, 'executedSwaps'>[];
}) => {
  if (!sourceAsset || !destinationAsset || !swapRequests) return null;
  const steps = getSwapSteps(swapRequests, sourceAsset, destinationAsset);

  return <SwapSteps steps={steps} />;
};

export const SwapRouteWithSymbols = ({
  routeInfo: { sourceAsset, destinationAsset },
  firstTokenStyle,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  firstTokenStyle?: string;
}) => {
  if (!sourceAsset || !destinationAsset) return null;
  const steps = getSwapSteps([], sourceAsset, destinationAsset);

  return (
    <>
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <div className={classNames(index === 0 && firstTokenStyle, 'w-fit')}>
            <div className="flex items-center gap-x-1">
              <TokenWithChainByAsset
                asset={step.asset}
                displayChainLogo={index === 0 || index === steps.length - 1}
                size="small"
              />
              {(index === 0 || index === steps.length - 1) && (
                <span className="font-normal text-white">
                  {chainflipAssetMap[step.asset].symbol}
                </span>
              )}
            </div>
          </div>
          <ArrowIcon
            width={16}
            className={classNames(
              'text-cf-light-2',
              index + 1 === steps.length ? 'hidden' : 'block',
            )}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export const CompactSwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swapRequest,
  highlightAssets,
  flat = false,
  withTooltip = false,
  hideAmount = false,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swapRequest?: Pick<SwapRequestFragment, 'executedSwaps' | RouteTooltipField>;
  highlightAssets?: ChainflipAsset[];
  flat?: boolean;
  withTooltip?: boolean;
  hideAmount?: boolean;
}) => {
  if (!sourceAsset || !destinationAsset) return null;
  const steps = getSwapSteps(swapRequest ? [swapRequest] : [], sourceAsset, destinationAsset);

  const route = (
    <div
      className={classNames(
        'flex items-center',
        flat
          ? 'gap-x-1 px-0.5 py-px'
          : 'gap-x-2 rounded-[6px] border border-cf-gray-4 bg-cf-gray-3 px-2 py-1.5',
      )}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <TokenAmountWithChainByAsset
            asset={step.asset}
            amount={hideAmount ? '' : step.amount}
            amountClass="text-12 text-white font-aeonikMono"
            displayChainLogo={!(index === 1 && steps.length > 2)}
            size="small"
            compact
            disabled={highlightAssets && !highlightAssets.includes(step.asset)}
          />
          <ArrowIcon
            width={16}
            className={classNames(
              'text-cf-light-2',
              index + 1 === steps.length ? 'hidden' : 'block',
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );

  if (!withTooltip || !swapRequest) return route;

  return (
    <Tooltip
      disabled={swapRequest?.executedSwaps.totalCount === 0}
      content={<RouteTooltip type="Route" swapRequest={swapRequest} />}
    >
      {route}
    </Tooltip>
  );
};
